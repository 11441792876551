@import "react-toastify/dist/ReactToastify.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-2xl: 40px;

  /* Light Mode Colors */
  --color-amber-50: #fffbeb;
  --color-amber-100: #fef3c7;
  --color-amber-200: #fde68a;
  --color-amber-300: #fcd34d;
  --color-amber-400: #fbbf24;
  --color-amber-500: #f59e0b;
  --color-amber-600: #d97706;
  --color-amber-700: #b45309;
  --color-amber-800: #92400e;
  --color-amber-900: #78350f;
  --color-amber-950: #451a03;

  /* Neutral Colors */
  --color-neutral-50: #fafafa;
  --color-neutral-100: #f5f5f5;
  --color-neutral-200: #e5e5e5;
  --color-neutral-300: #d4d4d4;
  --color-neutral-400: #a3a3a3;
  --color-neutral-500: #737373;
  --color-neutral-600: #525252;
  --color-neutral-700: #404040;
  --color-neutral-800: #262626;
  --color-neutral-900: #171717;
  --color-neutral-950: #0a0a0a;

  /* Sky Colors */
  --color-sky-50: #f0f9ff;
  --color-sky-100: #e0f2fe;
  --color-sky-200: #bae6fd;
  --color-sky-300: #7dd3fc;
  --color-sky-400: #38bdf8;
  --color-sky-500: #0ea5e9;
  --color-sky-600: #0284c7;
  --color-sky-700: #0369a1;
  --color-sky-800: #075985;
  --color-sky-900: #0c4a6e;
  --color-sky-950: #082f49;

  /* Light Mode */
  --bg-color: var(--color-amber-50);
  --text-color: var(--color-amber-900);
  --primary-color: var(--color-amber-500);
  --secondary-color: var(--color-amber-200);
}

:root.dark {
  --color-slate-50: #f8fafc;
  --color-slate-100: #f1f5f9;
  --color-slate-200: #e2e8f0;
  --color-slate-300: #cbd5e1;
  --color-slate-400: #94a3b8;
  --color-slate-500: #64748b;
  --color-slate-600: #475569;
  --color-slate-700: #334155;
  --color-slate-800: #1e293b;
  --color-slate-900: #0f172a;
  --color-slate-950: #020617;
}

body {
  font-family: var(--font-primary);
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.container {
  padding: var(--spacing-md);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

p {
  margin-bottom: var(--spacing-md);
}
ul,
ol {
  list-style-type: none;
  padding-left: 0;
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--secondary-color);
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  padding: var(--spacing-xs) var(--spacing-sm);
}

.nav-link:hover {
  color: var(--primary-color);
}

.card {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: inherit;
}

@layer components {
  .spinner {
    margin: 3.2rem auto 1.6rem;
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid theme("colors.amber.900");
    border-right-color: theme("colors.amber.200");
    animation: rotate 1s infinite linear;
  }

  .spinner-mini {
    margin: 0;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid theme("colors.amber.200");
    border-right-color: transparent;
    animation: rotate 1s infinite linear;
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
}

/* Custom styles for the calendar */
.calendar {
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  overflow: hidden;
}

.calendar-cell {
  text-align: center;
  padding: 0.5rem;
}

.calendar-cell .day {
  display: block;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: var(--color-amber-50);
  color: var(--color-amber-700);
}

.calendar-cell .day:hover {
  background-color: var(--color-amber-100);
  color: var(--color-amber-900);
}

.calendar-cell--today {
  background-color: var(--color-amber-200);
  color: var(--color-amber-900);
}

.calendar-cell--future {
  background-color: var(--color-amber-50);
  color: var(--color-amber-500);
  pointer-events: none; /* Make future dates not clickable */
  opacity: 0.5;
}

.calendar button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
}

.calendar button:focus {
  outline: none;
}

.calendar button:hover {
  color: var(--color-amber-900);
}

/*Scrollbar Modal Behavior*/
.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Fallback für Firefox */
.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

@media (max-width: 768px) {
  .calendar {
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    overflow: hidden;
  }

  .calendar-cell {
    text-align: center;
    padding: 0.25rem;
  }

  .calendar-cell .day {
    display: block;
    padding: 0.25rem;
    border-radius: 0.375rem;
    background-color: var(--color-amber-50);
    color: var(--color-amber-700);
    font-size: 0.75rem;
  }
}

/* In your CSS file */
.fc .fc-daygrid-day-frame {
  min-height: 100px; /* Set a minimum height for day cells */
}

.fc .fc-daygrid-day-number {
  font-size: 1rem; /* Adjust the font size of day numbers */
}

.fc .fc-event-title {
  font-size: 0.875rem; /* Adjust the font size of event titles */
}

.fc .fc-event img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Maintain aspect ratio */
}

/* Add media queries for better responsiveness */
@media (max-width: 768px) {
  .fc .fc-daygrid-day-frame {
    min-height: 80px; /* Adjust for smaller screens */
  }

  .fc .fc-daygrid-day-number {
    font-size: 0.875rem; /* Adjust for smaller screens */
  }

  .fc .fc-event-title {
    font-size: 0.75rem; /* Adjust for smaller screens */
  }
}
